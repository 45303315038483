<template>
    <div class="grid-cols-4 w-full absolute z-60 bg-white hidden md:grid -mt-4" v-if="active"
         @mouseenter="menuShown(true)" @mouseleave="menuShown(false)">
        <div class="col-span-4 flex flex-col flex-wrap p-4 max-h-[300px] h-[300px]">
            <div class="my-1 px-1 flex items-center space-x-2">
                <div class="rounded-full bg-bordergray p-2 w-[40px] h-[40px]">
                    <img src="/images/tum-urunler.png" alt="" class="w-full">
                </div>
                <Link @click="menuShown(false)" :href="`/kategoriler/tum-urunler`">
                    <h3 class="font-semibold text-base">Tüm Ürünler</h3>
                </Link>
            </div>
            <template v-for="(menu, index) in menuItems" :key="index">
                <div class="my-1 px-1 flex items-center space-x-2">
                    <div class="rounded-full bg-bordergray p-2 w-[40px] h-[40px]">
                        <img v-if="index === 'edcf4cb7-d8a3-462d-9659-2f5da5f42ae6'" src="https://kiralabunu.fra1.cdn.digitaloceanspaces.com/collections/90-kiralaminipng.png" alt="" class="w-full">
                        <img v-if="index === 'ae8494ef-9ecd-4015-96de-8fba185ecb64'" src="/images/kiralamotor.png" alt="" class="w-full">
                        <img v-else :src="menu.data.icon" alt="" class="w-full">
                    </div>
                    <h3 class="">
                        <Link class="font-semibold text-base hover:text-gray-700 transition-all"
                              v-if="menu.data.category_slug" @click="menuShown(false)"
                              :href="`/kategoriler/${menu.data.category_slug}`">
                            {{ menu.label }}
                        </Link>
                        <a class="font-semibold text-base hover:text-gray-800 transition-all"
                           v-if="menu.data.url && index == 'ae8494ef-9ecd-4015-96de-8fba185ecb64'" @click="menuShown(false)" :href="`${menu.data.url}`">
                            {{ menu.label }}
                        </a>
                        <a class="font-semibold text-base hover:text-gray-800 transition-all" :target="`${menu.data.target}`"
                           v-if="menu.data.url && index != 'ae8494ef-9ecd-4015-96de-8fba185ecb64'" @click="menuShown(false)" :href="`${menu.data.url}`">
                            {{ menu.label }}
                        </a>
                    </h3>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";

export default {
    name: "Menu",
    props: {
        active: {
            type: Boolean,
            default: true
        },
        menuItems: Object
    },
    emits: ["menuchange"],
    methods: {
        menuShown(val) {
            //console.log('close');
            this.$emit("menuchange", val);
        }
    },
    components: {
        Link
    }
};
</script>

<style scoped></style>
